<template>
  <!-- call-area -->
  <div class="call-area">
    <!-- call-setting -->
    <div class="call-setting">
      <div class="text-right">
        <a
          class="call-modal"
          href="#"
          data-toggle="modal"
          data-target="#exampleModal50"
          >Create a New Number</a
        >
      </div>

      <!-- leads-table-area  -->
      <div class="leads-table-area call-table">
        <table class="display" id="call">
          <thead>
            <tr>
              <th>Source</th>
              <th>Phone Number</th>
              <th>Type</th>
              <th>Forward to</th>
              <th style="width: 70px">Action</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
      <!-- leads-table-area  -->
    </div>
    <!-- call-setting -->
    <div
      class="modal fade"
      id="exampleModal50"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Create a New Number
            </h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <div class="modal-body">
            <div class="finece-option">
              <form method="">
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="inputEmail4">Phone number</label>
                    <input
                      type="text"
                      v-on:input="searchNumbers"
                      v-model="searchnumber"
                      class="form-control"
                      id="inputEmail42"
                      placeholder="Enter area code"
                    />
                    <img
                      v-if="numbers.length == 0 && searchnumber && display"
                      style="
                        position: absolute;
                        left: 93%;
                        width: 28px;
                        top: 28px;
                      "
                      src="../../assets/img/Spinner.gif"
                    />
                    <ul
                      v-if="numbers.length > 0"
                      style="
                        background: #fff;
                        height: 101px;
                        overflow-y: scroll;
                      "
                    >
                      <li
                        v-for="(number, index) in numbers"
                        :key="index"
                        style="padding: 5px"
                      >
                        <div class="box">
                          <div class="">
                            <span @click="selectnumber(number)">{{
                              number
                            }}</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <!-- <autocomplete
                          ref="autocomplete"
                          placeholder="Search Inventory"
                          input-class="form-control border-0"
                          :source="
                            'https://autolinkme.com/webservices/crm/inventory?id=' +
                            $storage.get('auth').user.dealer_autolink_id +
                            '&limit=20&offset=0&status=active&search_by=value&search_string='
                          "
                          results-property="data"
                          :results-display="formattedAutocompleteDisplay"
                          
                          
                          :showNoResults="false"
                      
                        >
                        </autocomplete> -->
                  </div>

                  <div class="form-group col-md-12">
                    <label for="inputEmail4">Source</label>
                    <select
                      id="inputState"
                      v-model="callsetting.source"
                      class="form-control"
                    >
                      <option selected>Select your source</option>
                      <option value="google">Google</option>
                      <option value="facebook">Facebook</option>
                      <option value="website">Website</option>
                    </select>
                  </div>

                  <div class="form-group col-md-12">
                    <label for="inputEmail4">Type</label>
                    <select
                      id="inputState"
                      v-model="callsetting.type"
                      class="form-control"
                    >
                      <option selected>Select your type</option>
                      <option value="sales">Sales</option>
                      <option value="service">Service</option>
                    </select>
                  </div>

                  <div class="form-group col-md-12">
                    <label for="inputEmail4">Forward to</label>
                    <input
                      type="text"
                      v-model="callsetting.forward"
                      class="form-control"
                      id="inputEmail42"
                      placeholder="+123-22-223-00"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer">
            <a class="rem-btn" @click="buyNumber"
              ><img
                class="img-fluid"
                src="../../assets/img/23. Close.svg"
                alt=""
              />
              Submit</a
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="editcallmodal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Edit Number</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <div class="modal-body">
            <div class="finece-option">
              <form method="">
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="inputEmail4">Phone number</label>

                    <input
                      type="text"
                      v-model="detail.number"
                      class="form-control"
                      id="inputEmail42"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-12">
                    <label for="inputEmail4">Source</label>
                    <select
                      id="inputState"
                      v-model="detail.source"
                      class="form-control"
                      disabled
                    >
                      <option selected>Select your source</option>
                      <option value="google">Google</option>
                      <option value="facebook">Facebook</option>
                      <option value="website">Website</option>
                    </select>
                  </div>

                  <div class="form-group col-md-12">
                    <label for="inputEmail4">Type</label>
                    <select
                      id="inputState"
                      v-model="detail.type"
                      class="form-control"
                      disabled
                    >
                      <option selected>Select your type</option>
                      <option value="sales">Sales</option>
                      <option value="service">Service</option>
                    </select>
                  </div>

                  <div class="form-group col-md-12">
                    <label for="inputEmail4">Forward to</label>
                    <input
                      type="text"
                      v-model="detail.forward"
                      class="form-control"
                      id="inputEmail42"
                      placeholder="+123-22-223-00"
                    />
                    <input type="hidden" v-model="detail.id" />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer">
            <a class="rem-btn" @click="editNumber"
              ><img
                class="img-fluid"
                src="../../assets/img/23. Close.svg"
                alt=""
              />
              Submit</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- call-area -->
</template>
<script>
import $ from "jquery";
import axios from "axios";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
// import Autocomplete from "vuejs-auto-complete";
export default {
  data() {
    return {
      searchnumber: "",
      numbers: [],
      callsetting: {
        number: "",
      },
      display: true,
      detail: {},
    };
  },
  // components: {
  //     VueSimpleSuggest
  // },
  methods: {
    selectnumber(num) {
      this.callsetting.number = num;
      this.searchnumber = num;
      this.numbers = [];
      this.display = false;
    },
    searchNumbers() {
      this.loader = true;
      this.display = true;
      if (this.searchnumber) {
        axios
          .get("searchnumber/" + this.searchnumber, {
            params: {
              dealer_id:  this.$storage.get('auth').user.dealer_id,
            },
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          })
          .then((res) => {
            this.numbers = res.data.numbers;
            this.loader = false;
          })
          .catch((err) => {
            console.log(err);
            this.loader = false;
          });
      } else {
        this.numbers = [];
        this.loader = false;
      }
    },
    buyNumber() {
      axios
        .post("purchaseNewNumber", this.callsetting, {
           params: {
              dealer_id:  this.$storage.get('auth').user.dealer_id,
            },
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.success) {
            this.callsetting = {};
            $("#exampleModal50").modal("hide");
            this.$toast.open({
              position: "top-right",
              message: res.data.message,
              type: "success",
            });
          } else {
            console.log("44");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editNumber() {
      axios
        .post("editnumber", this.detail, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.success) {
            $("#editcallmodal").modal("hide");
            $("#call").dataTable().fnDraw();
          } else {
            console.log("44");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getlogDeatils(id) {
      axios
        .get("getcallsettingdetail/" + id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((resp) => {
          this.detail = resp.data.detail;
          $("#editcallmodal").modal("show");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    let v = this;
    $("#call")
      .addClass("nowrap")
      .dataTable({
        responsive: true,
        fixedHeader: true,
        processing: true,
        serverSide: true,
        order: [[0, "desc"]],
        ajax: {
          url: axios.defaults.baseURL + "getcallsetting",
          type: "POST",
          headers: {
            Authorization: "Bearer " + v.$storage.get("auth").token,
          },
        },
        columns: [
          {
            data: "source",
          },
          {
            data: "number",
          },
          {
            data: "type",
          },
          {
            data: "forward",
          },
          {
            data: "action",
          },
        ],
        columnDefs: [{ targets: [3,4], orderable: false }],
      });

    $("tbody").on("click", ".opens", function () {
      var id = $(this).data("id");
      if (id !== undefined && id != "undefined" && id != null && id != "") {
        v.getlogDeatils(id);
      }
    });
  },
};
</script>
<style  scoped>
ul {
  /* list-style: none;
  margin-left: 30%;
  margin-right: 30%; */
}

li:hover {
  background: #ddd;
  cursor: pointer;
}
@import "../../assets/css/dataTables.css";
</style>